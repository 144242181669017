import React, { useEffect, useState } from "react";
import {
  Accordion,
  ButtonGroup,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  CORPORATION,
  CORP_TYPE,
  JobDetailType,
  getJobDescriptionListHelper,
  generateJobDescriptionObject,
  generateJobChallengeObject,
  JobDescriptionDetailsType,
  JobChallengeDetailsType,
} from "./jobDetailsManagementHelper";
import "./jobDetailsManagement.css";
import { JobDescirpiton } from "./JobDescription";
import { JobChallengeInfo } from "./JobChallengeInfo";
import { CopyModal } from "./CopyModal/CopyModal";
import { FILTER_DEFAULT, FILTER_TYPE, JobFilters } from "./Filters/Filters";
import { JobList } from "./JobList/JobList";

export const JobDetailsManagement: React.FC<{}> = () => {
  const [isLoadingJobList, setLoadingJobList] = useState<boolean>(false);
  const [filters, setFilters] = useState<FILTER_TYPE>(FILTER_DEFAULT);
  const [jobCorpType, setJobCorpType] = useState<CORP_TYPE>();
  const [jobList, setJobList] = useState<JobDetailType[]>();
  const [selectedJob, setSelectedJob] = useState<JobDetailType | undefined>();
  const [search, setSearch] = useState<string>("");
  const [isCopyModalOpen, setCopyModalOpen] = useState<boolean>(false);

  const [jobDescription, setJobDescription] =
    useState<JobDescriptionDetailsType[][]>();
  const [jobChallenge, setJobChallenge] = useState<JobChallengeDetailsType[]>();

  const loadJobList = async (corpType: CORP_TYPE) => {
    setLoadingJobList(true);
    const response = await getJobDescriptionListHelper(corpType);
    setJobList(response);
    setLoadingJobList(false);
  };

  const reset = () => {
    setSelectedJob(undefined);
    setFilters(FILTER_DEFAULT);
    setSearch("");
  };

  useEffect(() => {
    if (jobCorpType) {
      loadJobList(jobCorpType);
      reset();
    }
  }, [jobCorpType]);

  useEffect(() => {
    if (selectedJob) {
      setJobDescription(generateJobDescriptionObject(selectedJob.description));
      if (jobCorpType === CORP_TYPE.APPRENTICESHIP)
        setJobChallenge(generateJobChallengeObject(selectedJob.challengeInfo));
    }
  }, [selectedJob, jobCorpType]);

  return (
    <div className="">
      <Container>
        {/* Job Type Dropdown and Filter Sections */}
        <Row>
          <Col sm={4}>
            <div className="action-container">
              <p>
                <strong>Please select the job type:</strong>
              </p>
              <Dropdown
                style={{ pointerEvents: isLoadingJobList ? "none" : "auto" }}
              >
                <Dropdown.Toggle
                  id="dropdown-autoclose-inside"
                  className="dropdown-box"
                >
                  {jobCorpType
                    ? `${CORPORATION[jobCorpType].label}`
                    : "Select job type"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setJobCorpType(CORP_TYPE.APPRENTICESHIP);
                      setSelectedJob(undefined);
                    }}
                  >
                    {CORPORATION[CORP_TYPE.APPRENTICESHIP].label}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setJobCorpType(CORP_TYPE.STAFF_AUG);
                      setSelectedJob(undefined);
                    }}
                  >
                    {CORPORATION[CORP_TYPE.STAFF_AUG].label}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
          <Col sm={8}>
            {jobCorpType && (
              <JobFilters
                filters={filters}
                setFilters={setFilters}
                resetAll={reset}
              />
            )}
          </Col>
        </Row>
        <hr />
        {/* Job List and Detail Edit Sections */}
        <Row>
          <Col sm={4}>
            {jobCorpType && (
              <div className="action-container">
                {isLoadingJobList ? (
                  <p>
                    <strong>Loading Jobs...</strong>
                  </p>
                ) : (
                  <>
                    <div>
                      <strong>
                        Jobs for {CORPORATION[jobCorpType].label}:
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Search by Job Name or Title"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </InputGroup>
                      </strong>
                    </div>
                    {/* search bar, filter options */}
                    <JobList
                      jobList={jobList || []}
                      selectedJob={selectedJob}
                      onClick={(j: JobDetailType) => {
                        setSelectedJob(j);
                      }}
                      filters={filters}
                      search={search}
                    />
                  </>
                )}
                <br />
              </div>
            )}
          </Col>
          <Col sm={8}>
            {jobCorpType && selectedJob && jobDescription !== undefined ? (
              <>
                <Row>
                  <Col sm={9}>
                    <p>
                      <strong>{selectedJob.label}</strong>
                    </p>
                  </Col>
                  <Col sm={3}>
                    <DropdownButton
                      title="Actions"
                      as={ButtonGroup}
                      size="sm"
                      key="job-actions"
                      variant="secondary"
                      id="dropdown-button-job-actions"
                      style={{ float: "right" }}
                    >
                      <Dropdown.Item
                        onClick={() => setCopyModalOpen(true)}
                        disabled={
                          !(
                            jobCorpType &&
                            selectedJob &&
                            jobList &&
                            jobList.length > 1
                          )
                        }
                      >
                        Copy Over
                      </Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <strong>Job Description Details</strong>
                    </Accordion.Header>
                    <Accordion.Body className="float-div">
                      <JobDescirpiton
                        jobCorpType={jobCorpType}
                        jobId={selectedJob.id}
                        jobDescription={jobDescription}
                        setJobDescription={setJobDescription}
                        salary={selectedJob.salary}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  {jobCorpType === "APPRENTICESHIP" && (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <strong>Challenge Information Details</strong>
                      </Accordion.Header>
                      <Accordion.Body className="float-div">
                        <JobChallengeInfo
                          jobCorpType={jobCorpType}
                          jobId={selectedJob.id}
                          jobChallenge={
                            jobChallenge || ([] as JobChallengeDetailsType[])
                          }
                          setJobChallenge={setJobChallenge}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              </>
            ) : (
              jobCorpType &&
              !isLoadingJobList && (
                <p>Details will be displayed once job is selected.</p>
              )
            )}
          </Col>
        </Row>
        {jobCorpType && selectedJob && (
          <CopyModal
            show={isCopyModalOpen}
            onHide={() => {
              setCopyModalOpen(false);
              loadJobList(jobCorpType);
            }}
            corpType={jobCorpType}
            selectedJob={selectedJob}
            jobList={jobList || []}
          />
        )}
      </Container>
    </div>
  );
};
