import React from "react";
import { ListGroup } from "react-bootstrap";
import { FILTER_TYPE } from "../Filters/Filters";
import { JobDetailType } from "../jobDetailsManagementHelper";

export const JobList: React.FC<{
  jobList: JobDetailType[];
  selectedJob: JobDetailType | undefined;
  onClick: any;
  filters?: FILTER_TYPE;
  search?: string;
}> = (props) => {
  const { jobList, selectedJob, onClick, filters, search } = props;
  return (
    <div style={{ maxHeight: "500px", overflowY: "auto" }}>
      <ListGroup as="ol">
        {jobList &&
          jobList
            .filter((job) => {
              if (filters) {
                if (filters.status !== "All" && filters.status !== job.status)
                  return false;
                if (
                  filters.quickCourseStartDate !== "All" &&
                  (!job.startDate || job.startDate < new Date())
                )
                  return false;
              }

              if (!search || search === "") return true;
              else
                return job.label
                  .toLocaleLowerCase()
                  .includes(search.toLowerCase());
            })
            .map((job: JobDetailType) => {
              return (
                <ListGroup.Item
                  as="li"
                  key={`job-${job.id}`}
                  className={`d-flex justify-content-between align-items-start ${
                    selectedJob && selectedJob.id === job.id
                      ? "selected-job"
                      : ""
                  }`}
                  onClick={() => onClick(job)}
                >
                  <div className="ms-2 me-auto">
                    <div className="fw-bold"> {job.name}</div>
                    {job.title}
                  </div>
                  {/* <Badge bg="primary" pill>
            14
          </Badge> */}
                </ListGroup.Item>
              );
            })}
      </ListGroup>
    </div>
  );
};
