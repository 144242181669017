import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  Overlay,
  Popover,
} from "react-bootstrap";
import {
  CORP_TYPE,
  JobDetailType,
  saveJobDescriptionAndChallengeInfoHelper,
} from "../jobDetailsManagementHelper";
import { FILTER_DEFAULT, FILTER_TYPE, JobFilters } from "../Filters/Filters";
import { JobList } from "../JobList/JobList";

export const CopyModal: React.FC<{
  show: boolean;
  onHide: () => void | undefined;
  corpType: CORP_TYPE;
  selectedJob: JobDetailType;
  jobList: JobDetailType[];
}> = (props) => {
  const [copyTo, setCopyTo] = useState<JobDetailType | undefined>(undefined);
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<FILTER_TYPE>(FILTER_DEFAULT);
  const [isCopying, setCopying] = useState<boolean>(false);

  const [popoverTitle, setPopoverTitle] = useState<string>("");
  const [popoverMsg, setPopoverMsg] = useState<string>("");
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const target = useRef(null);

  const reset = () => {
    setCopyTo(undefined);
    setFilters(FILTER_DEFAULT);
    setSearch("");
  };

  const handleCopy = async () => {
    if (copyTo) {
      setCopying(true);
      try {
        await saveJobDescriptionAndChallengeInfoHelper(
          props.corpType,
          copyTo.id,
          props.selectedJob.description,
          props.selectedJob.challengeInfo
        );
        setPopoverTitle("Success!");
        setPopoverMsg(
          `You have successfully copied cover details from ${props.selectedJob.label} to ${copyTo.label}`
        );
      } catch (e) {
        console.error(e);
        setPopoverTitle("Failed!");
        setPopoverMsg(`Something went wrong. Please try again.`);
      } finally {
        setShowPopover(true);
        reset();
        setCopying(false);
        setTimeout(() => {
          setShowPopover(false);
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (props.show) {
      setCopying(false);
      setCopyTo(undefined);
      setSearch("");
      setFilters(FILTER_DEFAULT);
    }
  }, [props.show]);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Copy {props.selectedJob?.label} to...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ pointerEvents: isCopying ? "none" : "auto" }}>
            <JobFilters
              filters={filters}
              setFilters={setFilters}
              hideTitleSection={true}
            />
          </div>{" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "5px",
              pointerEvents: isCopying ? "none" : "auto",
            }}
          >
            <InputGroup className="mb-3">
              Search:
              <Form.Control
                style={{ marginLeft: "5px" }}
                placeholder="Search by Job Name or Title"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </div>
          <JobList
            jobList={
              props.jobList.filter((j) => j.name !== props.selectedJob.name) ||
              []
            }
            selectedJob={copyTo}
            onClick={(j: JobDetailType) => {
              setCopyTo(j);
            }}
            filters={filters}
            search={search}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" disabled={isCopying} onClick={props.onHide}>
            Close
          </Button>
          <Button
            ref={target}
            disabled={isCopying || !copyTo}
            onClick={handleCopy}
          >
            {isCopying ? "Copying..." : "Copy"}
          </Button>

          <Overlay
            show={showPopover}
            target={target.current}
            placement="top"
            containerPadding={20}
          >
            <Popover id="popover-contained">
              <Popover.Header as="h3">{popoverTitle}</Popover.Header>
              <Popover.Body>{popoverMsg}</Popover.Body>
            </Popover>
          </Overlay>
        </Modal.Footer>
      </Modal>
    </>
  );
};
