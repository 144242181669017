import React from "react";
import {
  Button,
  Row,
  Col,
  DropdownButton,
  ButtonGroup,
  Dropdown,
} from "react-bootstrap";

export type FILTER_TYPE = {
  status: "All" | "Open" | "Closed";
  quickCourseStartDate: "All" | "In_Future_Date";
};

export const FILTER_DEFAULT: FILTER_TYPE = {
  status: "All",
  quickCourseStartDate: "All",
};

export const JobFilters: React.FC<{
  filters: FILTER_TYPE;
  setFilters: (filters: FILTER_TYPE) => void;
  resetAll?: () => void;
  title?: string;
  hideTitleSection?: boolean;
}> = (props) => {
  const { filters, setFilters, resetAll, title, hideTitleSection } = props;
  return (
    <div className="action-container">
      {!hideTitleSection && (
        <p>
          <strong>
            {title || "Additional Filters"}
            {resetAll && (
              <Button
                variant="link"
                style={{ padding: "1px" }}
                onClick={resetAll}
              >
                [Reset All]
              </Button>
            )}
            :
          </strong>
        </p>
      )}
      <Row>
        <Col sm={3}>
          Status:{` `}
          <DropdownButton
            title={filters.status}
            as={ButtonGroup}
            size="sm"
            key="filter-status"
            variant="info"
            id="dropdown-button-drop-job-status"
          >
            {["All", "Open", "Closed"].map((opt) => {
              return (
                <Dropdown.Item
                  onClick={() =>
                    props.setFilters({
                      ...filters,
                      status: opt as any,
                    })
                  }
                >
                  {opt}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </Col>
        <Col sm={5}>
          Course Start Date:{` `}
          <DropdownButton
            title={filters.quickCourseStartDate}
            as={ButtonGroup}
            size="sm"
            key="filter-quickCourseStartDate"
            variant="info"
            id="dropdown-button-drop-job-status"
          >
            {["All", "In_Future_Date"].map((opt) => {
              return (
                <Dropdown.Item
                  onClick={() =>
                    setFilters({
                      ...filters,
                      quickCourseStartDate: opt as any,
                    })
                  }
                >
                  {opt}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
        </Col>
      </Row>
    </div>
  );
};
